import React, { useEffect, useRef } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'

type InternalUserPanelProps = {
  productId: string
  salesPerDay?: number
  clickRankDocumentCount: number
  publishDate?: Date
}

const StyledList = styled.ul`
  ${s({
    position: 'absolute',
    zIndex: 1,
    bgcolor: 'colorBackgroundSite',
    border: '1px solid',
    borderColor: 'colorBodyText',
    color: 'colorBodyText',
    p: 5,
  })}
`

export const InternalUserPanel: React.FC<InternalUserPanelProps> = ({
  productId,
  clickRankDocumentCount,
  publishDate,
}) => {
  const formattedDate = useRef<string | null>(null)

  useEffect(() => {
    if (publishDate) {
      formattedDate.current = publishDate.toLocaleDateString()
    }
  }, [publishDate])

  return (
    <StyledList role="list">
      <li>
        <strong>Product No.:</strong> {productId}
      </li>
      <li>
        <strong>ClickRank Event Count:</strong>{' '}
        <span>{clickRankDocumentCount}</span>
      </li>
      <li>
        <strong>Publish Date:</strong> {formattedDate.current}
      </li>
    </StyledList>
  )
}
