import { useProductsLocaleText } from '../../../locale'
import { Module } from '../../types'

export const createCardDesktopConfiguration = ({
  localise,
}: {
  localise: ReturnType<typeof useProductsLocaleText>
}): Module[] => {
  return [
    {
      __typename: 'ModuleTwoColumn',
      left: [{ __typename: 'ModuleImages' }],
      right: [
        {
          __typename: 'ModuleGroupCardTabs',
          tabs: [
            {
              title: localise('tabs.card'),
              label: localise('tabs.card_label'),
              type: 'SINGLE',
              modules: [
                { __typename: 'ModuleVariants', type: 'radio' },
                { __typename: 'ModuleDispatch' },
                { __typename: 'ModuleQuantity' },
                { __typename: 'ModuleButtons' },
                { __typename: 'ModuleMoreInfo' },
              ],
            },
            {
              title: localise('tabs.group_card'),
              label: localise('tabs.group_card_label'),
              type: 'GROUP',
              modules: [
                {
                  __typename: 'ModuleList',
                  title: localise('tabs.group_card_list_title'),
                  items: [
                    localise('tabs.group_card_list_item_1'),
                    localise('tabs.group_card_list_item_2'),
                    localise('tabs.group_card_list_item_3'),
                    localise('tabs.group_card_list_item_4'),
                  ],
                },
                { __typename: 'ModuleVariants', type: 'dropdown' },
                { __typename: 'ModuleButtons', productType: 'groupCard' },
              ],
            },
          ],
          meta: { intent: 'change tab' },
        },
      ],
      ratio: '50/50',
    },
    { __typename: 'ModulePeopleAlsoViewedVariant' },
  ]
}
