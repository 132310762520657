import React, { useCallback, useState } from 'react'
import {
  Box,
  SecondaryButton,
  TertiaryButton,
} from '@moonpig/launchpad-components'
import { formatPrice } from '@moonpig/web-core-utils'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { Region } from '@moonpig/web-core-types'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useLanguage, useStoreId } from '@moonpig/web-core-stores'
import { useExperiment } from '@moonpig/web-core-experiments'
import { DepartmentsEnum } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { CardProductTile } from './CardProductTile'
import { NonCardProductTile } from './NonCardProductTile'
import {
  ProductItemEventEnum,
  ProductList,
  ProductClickedEvent,
  AddedToBasketEvent,
} from '../../types'
import { InternalUserPanel } from './InternalUserPanel'
import { ProductTileProduct } from './ProductTileProduct'
import { useLocaleText } from './ProductTile.locale'
import { getProductImageDimensions } from '../../utils/getProductImageDimensions'
import { isCardProduct } from '../../utils/isCardProduct'
import { isPortraitCard } from '../../utils/isPortraitCard'
import { createProductItemGAEvent } from '../../analytics'
import { transformPill } from '../../utils/transformPill'
import { useDigitalGiftingCardOptions } from '../../utils/useDigitalGiftingCardOptions/useDigitalGiftingCardOptions'
import { useProductModal } from '../../contexts/productModal/Context'
import { isDigitalGift } from '../../utils/isDigitalGift/isDigitalGift'
import { CardProductTileVariant } from './CardProductTileVariant/CardProductTileVariant'
import { CardProductTileGalleryVariant } from './CardProductTileGalleryVariant'
import { getCardFormat } from '../../utils/getCardFormat/getCardFormat'

const IMAGE_URL_SUFFIX = `?w=400`

const StyledSecondaryButton = styled(SecondaryButton)`
  && {
    ${s({
      p: 0,
      mb: { xs: 5, lg: 6 },
      mx: { xs: 5, lg: 6 },
    })}
  }
`

function useCallbackOnce<T>(callback: (arg: T) => void) {
  const [firstInvocation, setFirstInvocation] = useState(true)

  return React.useCallback(
    (arg: T) => {
      if (firstInvocation) {
        callback(arg)
        setFirstInvocation(false)
      }
    },
    [callback, firstInvocation, setFirstInvocation],
  )
}

type ReactMouseEvent = React.MouseEvent<HTMLAnchorElement, MouseEvent>

type Props = {
  product: ProductTileProduct
  trackingData: {
    pageType: string
    metaTitle?: string
    region?: Region
    tile: {
      productIndex: number
      totalNumberOfProducts: number
    }
    productList?: ProductList
  }
  ref?: React.MutableRefObject<null>
  onClick: (clickedEvent: ProductClickedEvent) => void
  onFirstClick?: (clickedEvent: ProductClickedEvent) => void
  onAddToBasket: (addedEvent: AddedToBasketEvent) => void
  isInternalUser?: boolean
  isFavourited: boolean
  handleFavourite: (
    product: ProductTileProduct,
    isFavouriteSelected: boolean,
    index: number,
  ) => Promise<{ removeWithConfirmation: boolean }>
  isGallery?: boolean | undefined
  showProductTabs?: boolean
  hideStickyCta?: boolean
  showOurPick?: boolean
  groupCardProject?: string
  tabbedCarousel?: boolean
}

export const ProductTile = React.forwardRef<HTMLAnchorElement, Props>(
  (
    {
      product,
      trackingData,
      onClick,
      onFirstClick,
      onAddToBasket,
      isFavourited,
      handleFavourite,
      isInternalUser = false,
      isGallery,
      showProductTabs = false,
      hideStickyCta = false,
      showOurPick = false,
      groupCardProject,
      tabbedCarousel,
    },
    ref,
  ) => {
    const showCardProductTileVariant =
      useExperiment('search-new-gallery-ux', 'Original').toLowerCase() ===
      'enabled'
    const {
      title,
      id: productId,
      masterVariant,
      rating,
      category: { slug: categorySlug },
      slug,
      publishDate,
      isLandscape,
      primaryProductPill,
      clickRankDocumentCount,
    } = product
    const region = useStoreId()
    const productIndex = trackingData.tile.productIndex

    const localiseText = useLocaleText()
    const language = useLanguage()
    const { show } = useProductModal()
    const { getCanSeeCardOptions, seeCardOptionsForProduct } =
      useDigitalGiftingCardOptions()

    const isOutlinedCtaButton =
      useExperiment('gallery-cta-experiment') === 'enabled'

    const { masterImage, price, fullPrice, discountedPercentage } =
      masterVariant

    const formattedPrice = formatPrice(
      price.centAmount,
      price.fractionDigits,
      price.currencyCode,
      undefined,
      language,
    )

    const formattedFullPrice = formatPrice(
      fullPrice.centAmount,
      fullPrice.fractionDigits,
      fullPrice.currencyCode,
      undefined,
      language,
    )

    const isCard =
      product.category && isCardProduct(product.category.department)

    const productDetailsURL = `/${region}/${categorySlug}/p/${slug}/${productId.toLowerCase()}/`

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const firstClickCallback = onFirstClick && useCallbackOnce(onFirstClick)

    const getTrackingInfo = () => {
      const variant = product.masterVariant.title
      const { pageType, metaTitle, productList, tile } = trackingData
      const trimmedPageType = pageType.trim()
      const getLabel = (listNameLabel: string) =>
        `${listNameLabel} | ${productIndex + 1}/${
          tile.totalNumberOfProducts
        } | ${product.id}`

      if (!productList) {
        const listNameLabel = metaTitle
          ? `${trimmedPageType} | ${metaTitle}`
          : trimmedPageType
        return {
          label: getLabel(listNameLabel),
          listNameLabel,
          variant,
        }
      }

      const carouselType = `${tabbedCarousel ? 'tabbed ' : ''}carousel`
      const modulesCount = `${productList.position}/${productList.totalNumberOfLists}`
      const listNameLabel = tabbedCarousel
        ? `${trimmedPageType} | ${productList.tabbedModuleTitle} | ${carouselType} | ${modulesCount} | ${productList.title} tab`
        : `${trimmedPageType} | ${productList.title} | ${carouselType} | ${modulesCount}`
      const label = getLabel(listNameLabel)
      return {
        label,
        listNameLabel,
        variant,
      }
    }
    const handleClick = useCallback(
      (e: ReactMouseEvent) => {
        e.preventDefault()

        if (isGallery && !isCard) {
          window.open(productDetailsURL, '_self')
        } else {
          show({
            index: productIndex,
            product,
            groupCardProject,
            configuration: { showProductTabs, showStickyCta: !hideStickyCta },
            onAddToBasket,
          })
        }

        const { listNameLabel, label, variant } = getTrackingInfo()
        trackGAEvent(
          createProductItemGAEvent({
            eventType: ProductItemEventEnum.SELECT_ITEM,
            product,
            index: productIndex,
            label,
            listName: listNameLabel,
            variant,
          }),
        )
        onClick({ product, variant: masterVariant, index: productIndex })

        if (firstClickCallback) {
          firstClickCallback({
            product,
            variant: masterVariant,
            index: productIndex,
          })
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onClick, product, masterVariant, productIndex, firstClickCallback],
    )

    const imageDimensions = getProductImageDimensions(
      isCard,
      masterVariant.key,
      isLandscape,
    )

    const CtaButton = isOutlinedCtaButton
      ? StyledSecondaryButton
      : TertiaryButton

    const { canSeeCardOptions, digitalGiftCtaToken } = getCanSeeCardOptions(
      product.category.department,
    )

    const { label: trackingLabel, listNameLabel } = getTrackingInfo()
    const seeCardOptionsForDigitalGift = () =>
      seeCardOptionsForProduct({
        productId,
        gaEvent: createProductItemGAEvent({
          eventType: ProductItemEventEnum.SELECT_ITEM,
          product,
          variant: product.masterVariant.title,
          index: productIndex,
          label: trackingLabel,
          listName: listNameLabel,
        }),
      })

    const renderCtaText = (
      productDepartment: DepartmentsEnum,
      children: React.ReactChildren,
    ) => {
      if (isDigitalGift(productDepartment)) {
        return localiseText(digitalGiftCtaToken)
      }

      return isOutlinedCtaButton
        ? localiseText('common.add_to_basket_cta')
        : children
    }

    const ctaClick = () =>
      canSeeCardOptions
        ? seeCardOptionsForDigitalGift()
        : onAddToBasket({
            product,
            index: productIndex || 0,
            productIndex: productIndex || 0,
            variant: product.masterVariant,
            quantity: 1,
            trackPersonaliseEvent: true,
          })

    const label = primaryProductPill
      ? primaryProductPill.displayLabel
      : undefined
    const variant = primaryProductPill
      ? transformPill(primaryProductPill).displayVariant
      : undefined

    return (
      <>
        {isInternalUser && (
          <InternalUserPanel
            productId={productId}
            publishDate={(publishDate && new Date(publishDate)) || undefined}
            clickRankDocumentCount={clickRankDocumentCount || 0}
          />
        )}
        {isCard &&
          isGallery &&
          (showCardProductTileVariant ? (
            <CardProductTileVariant
              image={{
                src: `${masterImage.url}${IMAGE_URL_SUFFIX}`,
                alt: title,
                dimensions: imageDimensions,
              }}
              onClick={handleClick}
              href={productDetailsURL}
              format={getCardFormat({
                isLandscape: !!isLandscape,
                variantKey: masterVariant.key,
              })}
              key={`product-${productId}`}
              pill={
                label && variant
                  ? { label, variant }
                  : /* istanbul ignore next */ undefined
              }
            />
          ) : (
            <CardProductTileGalleryVariant
              href={productDetailsURL}
              image={{
                src: `${masterImage.url}${IMAGE_URL_SUFFIX}`,
                alt: title,
              }}
              onClick={handleClick}
              key={`product-${productId}`}
              ref={ref}
              portraitCardAspectRatio={
                imageDimensions.width / imageDimensions.height
              }
              favourite={{
                isSelected: isFavourited,
                onSelect: () =>
                  handleFavourite(product, !!isFavourited, productIndex),
              }}
              label={label}
              variant={variant}
              sponsored={product.isSponsored}
            />
          ))}
        {isCard && !isGallery && (
          <Box width={1}>
            <CardProductTile
              href={productDetailsURL}
              image={{
                src: `${masterImage.url}${IMAGE_URL_SUFFIX}`,
                alt: title,
              }}
              onClick={handleClick}
              key={`product-${productId}`}
              ref={ref}
              portraitCardAspectRatio={
                isPortraitCard(masterVariant.key, isLandscape)
                  ? imageDimensions.width / imageDimensions.height
                  : /* istanbul ignore next */
                    undefined
              }
              favourite={{
                isSelected: isFavourited,
                onSelect: () =>
                  handleFavourite(product, !!isFavourited, productIndex),
              }}
              ourPick={showOurPick && productIndex === 0}
              sponsored={product.isSponsored}
              label={label}
              variant={variant}
            />
          </Box>
        )}
        {!isCard && (
          <NonCardProductTile
            productId={productId}
            href={productDetailsURL}
            title={title}
            price={formattedPrice}
            fullPrice={formattedFullPrice}
            discountedPercentage={discountedPercentage}
            image={{
              src: `${masterImage.url}${IMAGE_URL_SUFFIX}`,
              alt: title,
            }}
            rating={rating || undefined}
            titleAs="h3"
            priceAs="p"
            onClick={handleClick}
            key={`product-${productId}`}
            ref={ref as React.Ref<HTMLDivElement>}
            ourPick={showOurPick && productIndex === 0}
            sponsored={product.isSponsored}
            favourite={{
              isSelected: isFavourited,
              onSelect: () =>
                handleFavourite(product, !!isFavourited, productIndex),
            }}
            pill={
              primaryProductPill ? transformPill(primaryProductPill) : undefined
            }
            // eslint-disable-next-line react/no-unstable-nested-components
            linkAs={({ children, ...rest }) => (
              <CtaButton
                {...rest}
                onClick={async (e: MouseEvent) => {
                  e.preventDefault()
                  return ctaClick()
                }}
              >
                {renderCtaText(product.category.department, children)}
              </CtaButton>
            )}
          />
        )}
      </>
    )
  },
)

ProductTile.displayName = 'ProductTile'
