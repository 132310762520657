import { useRouter } from '@moonpig/web-core-routing'
import { useStoreId } from '@moonpig/web-core-stores'
import { match, useIsMobile } from '@moonpig/web-shared-utils'
import { useProductsLocaleText } from '../../locale'
import { ProductInfoProduct } from '../../types'
import { isCardProduct } from '../../utils/isCardProduct'
import { Module } from '../types'
import { createGroupCardProjectDesktopConfiguration } from './group-project/GroupCardProjectDesktop'
import { createGroupCardProjectMobileConfiguration } from './group-project/GroupCardProjectMobile'
import { createCardDesktopConfiguration } from './group/CardDesktop'
import { createCardDesktopMemberPriceConfiguration } from './group/CardDesktopMemberPrice'
import { createCardMobileConfiguration } from './group/CardMobile'
import { createCardMobileMemberPriceConfiguration } from './group/CardMobileMemberPrice'
import { createCardMultiBuyDesktopConfiguration } from './multibuy/CardMultiBuyDesktop'
import { createCardMultiBuyMobileConfiguration } from './multibuy/CardMultiBuyMobile'
import { createCardMultiBuyMobileMemberPriceConfiguration } from './multibuy/CardMultiBuyMobileMemberPrice'
import { createCardMultiBuyDesktopMemberPriceConfiguration } from './multibuy/CardMultiBuyDesktopMemberPrice'
import { createCardOutOfStockDesktopConfiguration } from './out-of-stock/CardOutOfStockDesktop'
import { createCardOutOfStockMobileConfiguration } from './out-of-stock/CardOutOfStockMobile'

export const useDefaultModuleConfiguration = (context: {
  flags: Record<string, boolean>
}) => {
  const storeId = useStoreId()
  const localise = useProductsLocaleText()
  const isMobile = useIsMobile()
  const router = useRouter()

  return ({ product }: { product: ProductInfoProduct }): Module[] => {
    const isGroupCardProject =
      !!router.getCurrentRoute<'search'>()?.params?.groupCardProject

    const conditions = {
      isCard: isCardProduct(product.category.department),
      inStock: product.variants.some(({ inStock }) => inStock),
      isMobile,
      isGroupCardProject,
      storeId,
      isMemberPriceEnabled: context.flags['loyalty-enable-member-pricing'],
    }

    return match(conditions)
      .with(
        { isCard: false }, // prettier-ignore
        () => [], // prettier-ignore
      )
      .with(
        { inStock: false, isMobile: true }, // prettier-ignore
        () => createCardOutOfStockMobileConfiguration(), // prettier-ignore
      )
      .with(
        { inStock: false, isMobile: false }, // prettier-ignore
        () => createCardOutOfStockDesktopConfiguration(), // prettier-ignore
      )
      .with(
        { isGroupCardProject: true, isMobile: true,  }, // prettier-ignore
        () => createGroupCardProjectMobileConfiguration(), // prettier-ignore
      )
      .with(
        { isGroupCardProject: true, isMobile: false, }, // prettier-ignore
        () => createGroupCardProjectDesktopConfiguration(), // prettier-ignore
      )
      .with(
        { storeId: 'uk', isMobile: true, isMemberPriceEnabled: true }, // prettier-ignore
        () => createCardMobileMemberPriceConfiguration({ localise }), // prettier-ignore
      )
      .with(
        { storeId: 'uk', isMobile: false, isMemberPriceEnabled: true }, // prettier-ignore
        () => createCardDesktopMemberPriceConfiguration({ localise }), // prettier-ignore
      )
      .with(
        { storeId: 'uk', isMobile: true }, // prettier-ignore
        () => createCardMobileConfiguration({ localise }), // prettier-ignore
      )
      .with(
        { storeId: 'uk', isMobile: false }, // prettier-ignore
        () => createCardDesktopConfiguration({ localise }), // prettier-ignore
      )
      .with(
        { isMobile: true, isMemberPriceEnabled: true }, // prettier-ignore
        () => createCardMultiBuyMobileMemberPriceConfiguration({ localise, storeId }), // prettier-ignore
      )
      .with(
        { isMobile: false, isMemberPriceEnabled: true }, // prettier-ignore
        () => createCardMultiBuyDesktopMemberPriceConfiguration({ localise, storeId }), // prettier-ignore
      )
      .with(
        { isMobile: true }, // prettier-ignore
        () => createCardMultiBuyMobileConfiguration({localise, storeId }), // prettier-ignore
      )
      .with(
        { isMobile: false }, // prettier-ignore
        () => createCardMultiBuyDesktopConfiguration({localise, storeId }), // prettier-ignore
      )
      .otherwise(/* istanbul ignore next */ () => [])
  }
}
